import React, { useEffect, useState } from 'react'
import { ResultContainer, SimulatorContainer } from './style'
import { Input } from '@interco/inter-ui/components/Input'
import { Button } from '@interco/inter-ui/components/Button'
import { Select, SingleValue, SelectOption } from '@interco/inter-ui/components/Select'
import simulate from '../../calcs/_simulationCalcs'
import { floatToCurrency, currencyToFloat } from '../../calcs/_formatCurrency'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export default function Calculator () {
  const [ selected, setSelected ] = useState<SingleValue<SelectOption>>({ label: '1 parcela', value: 1 })
  const [ result, setResult ] = useState<number>(0)
  const [ totalValue, setTotalValue ] = useState<number>(0)
  const [ showTotalValue, setShowTotalValue ] = useState<number>(0)
  const [ error, setError ] = useState<boolean>(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  async function handleCalculate (remaining: number | string, totalValue: number) {
    const res = await simulate(remaining, totalValue)
    setShowTotalValue(totalValue)
    setResult(res)
  }

  function handleTotalValue (value: string) {
    setTotalValue(currencyToFloat(value))
    setError(currencyToFloat(value) < 1)
  }

  useEffect(() => {
    if (result) {
      sendDatalayerEvent({
        section: 'dobra_02',
        element_action: 'click button',
        element_name: 'Calcular',
        section_name: 'Calculadora de Pontos',
        value: result,
      })
    }
  }, [ result ])

  const options: SelectOption[] = [
    { label: '1 parcela', value: 1 },
    { label: '2 parcelas', value: 2 },
    { label: '3 parcelas', value: 3 },
    { label: '4 parcelas', value: 4 },
    { label: '5 parcelas', value: 5 },
    { label: '6 parcelas', value: 6 },
    { label: '7 parcelas', value: 7 },
    { label: '8 parcelas', value: 8 },
    { label: '9 parcelas', value: 9 },
    { label: '10 parcelas', value: 10 },
    { label: '11 parcelas', value: 11 },
    { label: '12 ou mais parcelas', value: 12 },
  ]

  return (
    <SimulatorContainer id='calculator-85'>
      <div className='container'>
        <div className='row align-items-end'>
          <div className='col-12 text-center mb-md-4'>
            <h2 className='fs-24 lh-24 fs-md-28 lh-md-32 fs-lg-48 lh-lg-54 text-grayscale--500 fw-400'>
              Calculadora de Pontos
            </h2>
            <p className='fs-12 lh-14 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Na área "Antecipação de Parcelas" do Super App, escolha as compras que você quer antecipar <span className='d-lg-block'>e insira os valores abaixo para conferir os pontos extras.</span>
            </p>
          </div>
          <div className='col-12 col-md-5 my-3 my-md-0' style={{ marginTop: -12 }}>
            <Select
              id='select-85'
              label='Quantidade de parcelas restantes'
              options={options}
              placeholder='Selecione uma opção'
              onChange={(value) => {
                setSelected(value)
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Calcular',
                  section_name: 'Quantidade de parcelas restantes',
                  value: value?.value,
                })
              }}
              className='orange-arrow'
            />
          </div>
          <div className='col-12 col-md-5 my-3 my-md-0'>
            <Input
              style={{ width: '100%' }}
              id='value2'
              label='Valor total'
              placeholder='R$ 0,00'
              value={floatToCurrency(totalValue)}
              onBlur={() => sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Valor total',
                section_name: 'Quantidade de parcelas restantes',
                value: totalValue,
              })}
              onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                handleTotalValue(value.target.value)
              }}
              infoText={error ? 'Valor mínimo de R$ 1,00' : ''}
              maxLength={15}
              error={error}
              className='pb-0'
            />
          </div>
          <div className='col-12 col-md-2 mt-3 mt-md-0'>
            <Button
              onClick={() => {
                handleCalculate(selected.value, totalValue)
              }}
              fullWidth
              disabled={error}
            >
              Calcular
            </Button>
          </div>
          <div className='col-12 text-center mt-3'>
            <ResultContainer id='value3'>{result}</ResultContainer>
          </div>
          <div className='col-12 text-center'>
            <p className='fs-12 fs-md-20 text-grayscale--500 font-citrina fw-500'>Pontos extras, antecipando R${floatToCurrency(showTotalValue)}</p>
          </div>
        </div>
      </div>
    </SimulatorContainer>
  )
}
