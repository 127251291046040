export function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function currencyToFloat (currencyNumber: string) {
  const result =
    currencyNumber === ''
      ? 0
      : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return result
}
