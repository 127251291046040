function selectMultiplier (remaining: number) {
  let multiplier = 0.0249

  switch (remaining) {
    case 1:
      multiplier = 0.0038
      break
    case 2:
      multiplier = 0.0057
      break
    case 3:
      multiplier = 0.0077
      break
    case 4:
      multiplier = 0.0096
      break
    case 5:
      multiplier = 0.0115
      break
    case 6:
      multiplier = 0.0134
      break
    case 7:
      multiplier = 0.0153
      break
    case 8:
      multiplier = 0.0172
      break
    case 9:
      multiplier = 0.0191
      break
    case 10:
      multiplier = 0.0210
      break
    case 11:
      multiplier = 0.0230
      break
    default: multiplier = 0.0249
  }
  return multiplier
}

export default function simulate (remaining: number, totalValue: number) {
  const multiplier = selectMultiplier(remaining)
  const result = (multiplier * totalValue * 1000) / 25
  return Math.floor(parseInt(result.toFixed(1)))
}
