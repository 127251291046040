import React from 'react'
import Faq from 'src/components/Faq/index'

type StructuredData = {
  faq: {
    answer: string;
    question: string;
  }[];
}

export default function FAQ ({ pageContext }: { pageContext: {structuredData: StructuredData} }) {
  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-28 lh-32 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-56 text-grayscale--500 fw-500 mb-3'>
              Perguntas frequentes
            </h2>
            <Faq columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} answerData={pageContext.structuredData.faq} />
          </div>
        </div>
      </div>
    </section>
  )
}
