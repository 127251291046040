import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import { sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const SimulatorContainer = styled.section`
  background-color: ${sand};
  input {
    width: 100%;
    max-width: 100%;
  }

  .orange-arrow {
    svg {
      path {
        stroke: ${orange.extra};
      }
    }
  }
`
export const ResultContainer = styled.p`
  font-size: 54px;
  line-height: 60px;
  font-weight: 600;
  color: ${grayscale[500]};
  font-family: 'Citrina', sans-serif;

  @media ${device.tablet} {
    font-size: 104px;
    line-height: 104px;
  }

`
