import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`
  h1, h2 {
    font-family: 'Citrina', sans-serif;
  }
  section {
    padding: 20px 0;

    @media ${device.desktopLG} {
      padding: 60px 0;
    }

    @media ${device.desktopXL} {
      padding: 96px 0;
    }
  }
`
